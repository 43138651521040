<template>
  <TopNav></TopNav>
  <h1>İnç Hesaplama</h1>
  <fieldset>
    <legend>İnç Hesaplama Aracı</legend>
    <input type="number" v-model.number="santimetre" step="any" @input="santimetreDegistiginde" autofocus id="cm"> <label for="cm"><abbr title="santimetre">㎝</abbr></label>
    <br>
    <input type="number" v-model.number="inc" step="any" @input="incDegistiginde" id="inc"> <label for="inc"><abbr title="inç">in</abbr></label>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "Inc",
  data() {
    return {
      santimetre: 0,
      inc: 0
    }
  },
  components: {
    TopNav
  },
  methods: {
    santimetreDegistiginde(e) {
      const santimetre = e.currentTarget.value;
      this.inc = santimetre * 0.3937007874;
    },
    incDegistiginde(e) {
      const inc = e.currentTarget.value;
      this.santimetre = inc * 2.54;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 120px;
}

footer section a {
  margin: 20px;
}
</style>
